import React from 'react';
import styled from '@emotion/styled';
import SabineJPG from '@assets/images/sabine.jpeg';
import OchsJPG from '@assets/images/ochs.jpeg';
import NeuroRoomJPG from '@assets/images/neuro-room.jpg';
import NeuroRoomMobileJPG from '@assets/images/neuro-room-mobile.jpg';
import Teaser from '~/components/atoms/Teaser/Teaser';
import Duo from '~/components/molecules/Duo/Duo';
import { ImageContainerHome } from '~/utils/styles/utils';
import Image from '~/components/atoms/Image/Image';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroRoomJPG}
        mobileHero={NeuroRoomMobileJPG}
        altHero="Neurologie Praxisraum"
      >
        <ContainerSmall>
          <h1>Seite nicht gefunden.</h1>
        </ContainerSmall>
        <Teaser
          heading="Praxis für Neurologie, Neuropsychologie, Sportmedizin &amp; Psychiatrie"
          text="Im Neurozentrum Arabellapark bieten wir alle fachärztlichen Leistungen der Neurologie, Psychiatrie und Neuropsychologie an – von der Diagnostik bis zur Behandlung. 
          Dabei verfolgen wir einen ganzheitlichen Ansatz und beziehen beispielsweise Sporttherapie und Ernährungsberatung mit ein."
          whiteBackground
          small={false}
          link="/praxis"
          headingLevel={2}
        />
        <Duo>
          <ImageContainerHome>
            <Image src={SabineJPG} alt="Sabine" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={OchsJPG} alt="Ochs" width={600} />
          </ImageContainerHome>
        </Duo>
      </HeaderFooterLayout>
    </>
  );
};

const ContainerSmall = styled.div`
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1000px;
  }

  p {
    font-size: var(--primary-font-size);
    line-height: 1;
  }

  h1,
  h2,
  p {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default NotFoundPage;
